<template>
    <div>
        <Modal :title="title" v-model="isShowModal" width="640px">
            <template v-slot:modal-content>
                <p
                    v-if="isApplyRule"
                    class="result-status"
                    v-text="'已成功套用規則'"
                />
                <template v-else-if="!isApplyRule && result !== null">
                    <p class="result-status success" v-text="'檔案已上傳'" />
                    <div class="status-description">
                        <p>您上傳的檔案結果如下：</p>
                        <p>
                            已上傳檔名：<span>{{ result.fileName }}</span>
                        </p>
                        <p>
                            編輯成功數：
                            {{ result.validCount }} &nbsp;筆
                        </p>
                        <p v-if="result.invalidCount > 0">
                            編輯失敗數：

                            <span class="warning">
                                {{ result.invalidCount }}
                            </span>
                            &nbsp;筆
                        </p>
                    </div>
                    <div
                        v-if="result?.invalidCount > 0"
                        class="error-container"
                    >
                        <p>
                            編輯失敗的保單資訊將不會被更新
                            <br />請將下方錯誤訊息的保單，在excel檔中重新編輯後再次上傳
                        </p>

                        <div class="error-message-container">
                            <div
                                class="error-message"
                                v-for="(error, index) in result.invalidData"
                                :key="index"
                            >
                                <p class="wrong-row">
                                    Excel錯誤列數：{{ error.row }}
                                </p>
                                <p
                                    v-for="(
                                        column, columnIndex
                                    ) in error.columns"
                                    :key="columnIndex"
                                    class="wrong-column"
                                >
                                    錯誤欄位：{{
                                        (column.name === null
                                            ? '不明'
                                            : column.name) +
                                        '/' +
                                        column.description
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
            </template>

            <template v-slot:modal-bottom>
                <Button
                    buttonWord="關閉"
                    buttonStyle="grey"
                    @click="turnOffModal()"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'

export default {
    name: 'ProductResultModal',
    emits: ['update:modelValue'],
    components: {
        Modal,
        Button
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        result: {
            type: Object,
            default: function () {
                return null
            }
        },
        title: {
            type: String,
            default: '標題'
        },
        isApplyRule: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        turnOffModal: function () {
            this.isShowModal = false
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        }
    },
    watch: {},
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
p {
    margin: 0;
}

//result-content
.result-status {
    font-size: 32px;
    font-weight: bold;
    &.success {
        color: $primary-green;
    }
    &.fail {
        color: $primary-red;
    }
    margin-bottom: 10px;
}

.status-description {
    margin-bottom: 10px;
    font-size: 15px;
    p {
        color: $sixth-black;
        font-size: 15px;
        word-break: keep-all;
    }
    span {
        word-break: break-all;
        &.warning {
            color: $primary-red;
        }
    }
}

.error-container {
    border-top: 1px dashed $fourth-grey;
    padding-top: 10px;
    margin-bottom: 10px;
    > p {
        margin-bottom: 10px;
        color: $sixth-black;
        font-size: 14px;
    }
    .error-message-container {
        background-color: $primary-grey;
        padding: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 3px;
        border: 1px solid $primary-red;

        .error-message {
            &:not(:last-child) {
                margin-bottom: 15px;
            }

            p {
                font-size: 12px;
                color: $sixth-black;
                &.wrong-row {
                    font-weight: 500;
                }
                &.wrong-column {
                    font-weight: normal;
                }
            }
        }
    }
}
</style>
